<template>
    <div class="button-dropdown">
        <hb-btn :disabled="isDefault" class="ml-0 mt-4" icon tooltip="Drag" active-state-off>
            mdi-drag-custom
        </hb-btn>
        <hb-expansion-panel :disabled="isDefault">
            <template v-slot:title> 
                <div v-if="question.required == true"> {{ question.question + ' *'}} </div>
                <div v-else> {{ question.question}} </div>
            </template>
            <template v-if="isDefault === true" v-slot:actions>
                <HbStatusGeneral status="default" />
            </template>
            <template v-slot:content>
                <v-subheader class="hb-font-body">Ask a question and then add potential answers and responses to those
                    answers.</v-subheader>
                <v-card :elevation="0" class="mx-auto">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="hb-font-body-medium mb-1">
                                Question
                            </v-list-item-title>
                            <v-list-item-subtitle class="mt-2">What question would you like to ask?</v-list-item-subtitle>
                        </v-list-item-content>
                        
                    </v-list-item>
                    <v-list-item class="ma-0">
                        <HbTextField :readonly="readOnlyMode && !!questionId" v-validate="'required|min:10|max:125'" width="85%" full v-model="question.question" placeholder="Enter Question" data-vv-as="Question" data-vv-scope="default"  data-vv-name="question" :error="errors.has('default.question')"/>
                        <HbCheckbox :readonly="readOnlyMode && !!questionId" v-model="question.required" label="Required" @change="addDefaultAnswer" tooltip-body="Question marked as Required will automatically be given an answer option of N/A in the Lead Questions form."/>
                    </v-list-item>
                </v-card>

                <v-card :elevation="0" class="mx-auto" v-for="(answer, index) in question.answers" :key="index">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle class="mt-2">
                                Answer {{ index + 1 }}
                            </v-list-item-subtitle>
                            
                        </v-list-item-content>
                        <HbBtn class="mt-4 mr-0" v-if="!isDefault && !answer.requiredAnswer && (!readOnlyMode || !questionId)" icon tooltip="Close" mdi-code="mdi-close" @click="deleteOptions(index)"/>
                    </v-list-item>

                    <v-row class="ml-4">
                        <HbTextField :readonly="readOnlyMode && !!questionId" v-validate="'required|min:1|max:125'" width="85%" full v-model="answer.option" :disabled="answer.requiredAnswer" placeholder="Enter answer" :data-vv-as="'Answer' + (index+1)" data-vv-scope="default" :data-vv-name="'answer' + (index+1)" :error="errors.has(`default.answer${index+1}`)"/>
                    </v-row>

                    <v-row class="ml-3 mt-4 pb-3">
                        <HbCheckbox :readonly="readOnlyMode && !!questionId" v-model="answer.responseCheck" @change="resetValidatorsResponse" label="Add a Response" />
                    </v-row>
                    <div v-if="answer.responseCheck">
                        <v-card-text class="hb-font-body">Response</v-card-text>
                        <v-card-text class="mt-n2">
                            <HbTextField :readonly="readOnlyMode && !!questionId" v-validate="'required|min:1|max:125'" width="85%" full v-model="answer.response" placeholder="Enter a Response" :data-vv-as="'Response' + (index+1)" data-vv-scope="default" :data-vv-name="'response' + (index+1)" :error="errors.has(`default.answer${index+1}`)"/>
                        </v-card-text>
                    </div>
                </v-card>
                <div class="ml-4 py-3">
                    <hb-link v-if="!readOnlyMode || !questionId" @click="addOption">+ Add Answer</hb-link>
                </div>
                <div class="question-btn-save mx-4 py-3">
                    <hb-link v-if="readOnlyMode==false || !questionId" @click="cancelAllQuestion" class="pt-2">Cancel</hb-link>
                    <hb-btn v-if="readOnlyMode==false || !questionId" @click="saveQuestion" class="ml-3">Save</hb-btn>
                    <hb-btn v-else @click="enableUpdate" class="ml-3" color="secondary">Update</hb-btn>
                </div>
            </template>
        </hb-expansion-panel>
        <HbBtn class="mt-4 mr-0" v-if="!isDefault" icon tooltip="Close" mdi-code="mdi-close" @click="deleteDialog = true" />
        <hb-modal
            size="medium"
            title="Delete Question"
            confirmation
            v-model="deleteDialog"
        >
            <template v-slot:content>
                <div class="hb-body py-4 px-6">
                    Are you sure you want to delete the selected question?
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" @click="deleteQuestion">Delete</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script>
import { notificationMixin } from "../../../mixins/notificationMixin";
import api from '../../../assets/api';
export default {
    name: "LeadQuestionary",
    data() {
        return {
            disableBtn: true,
            responseCheck: false,
            deleteDialog: false,
            description: '',
            notification: false,
            type: '',
            readOnlyMode: true,
            errorsList: [],
        };
    },
    mixins: [notificationMixin],
    props: {
        question:{}, 
        isDefault: {
            type: Boolean,
            default: false
        },
        sortOrder: 0,
        questionId: '',
    },
    methods: {
        addOption() {
            this.question.answers.push({ option: '', requiredAnswer: false, responseCheck: false, response: '' });
        },
        deleteOptions(i){
            this.$validator.reset();
            this.errors.clear();
            this.question.answers.splice(i, 1);
        },
        async saveQuestion(){
            const valid = await this.$validator.validateAll('default');
            if (!valid) {
                this.description = "There are errors in your form, correct them before continuing."
                this.type = 'error';
                this.notification = true;
                return;
            } else {
                try {
                    if(this.readOnlyMode === true) {
                        let data = {
                        question: this.question,
                        sort_order: this.sortOrder
                        }
                        let r = await api.post(this, api.LEADS + 'questionnaire', data);
                        this.$emit("updateQuestions");
                        this.showMessageNotification({
                            type: 'success', 
                            description: 'Question saved successfully'
                        });
                    } else {
                        let data = {
                        question: this.question,
                        sort_order: this.sortOrder
                        }
                        let r = await api.put(this, api.LEADS + 'questionnaire/' + this.questionId , data);
                        this.$emit("updateQuestions");
                        this.readOnlyMode = true;
                        this.showMessageNotification({
                            type: 'success', 
                            description: 'Question was updated successfully'
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        enableUpdate() {
            this.readOnlyMode = false;
        },
        async deleteQuestion(){
            this.deleteDialog = false;
            let r = await api.delete(this, api.LEADS + 'questionnaire/' + this.questionId);
            this.$emit("updateQuestions");
        },
        addDefaultAnswer(){
            if(this.question.required)
                this.question.answers.unshift({ option: 'N/A', requiredAnswer: true, responseCheck: false, response: '' });
            else
                this.question.answers.splice(0, 1);
        },
        cancelAllQuestion(){
            this.readOnlyMode = true;
            this.$emit("updateQuestions");
        },
        resetValidatorsResponse(){
            this.errors.clear();
            this.$validator.reset();
        }
    },
}
</script>
<style>
    .button-dropdown{
        display: flex;
        flex-wrap: nowrap;
    }
    .question-btn-save{
        display: flex;
        justify-content: flex-end;
    }
</style>