<template>
    <hb-expansion-panel key="apw_setting" v-if="apwSettingState">
        <template v-slot:title> Advance Reservation and Rental</template>
        <template v-slot:content>
            <hb-form label="Cut-Off Time By Channel"
                description="The cut-off time is a daily time limit that determines when the booking window for a given day closes and shifts to the next available day.">
                <v-row class="cut-off-row-container ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                        In-Store Cut Off Time:
                    </v-col>
                    <v-col class="ma-0 pa-0">
                        <HbSelect condensed box width="110px" :items="timeOptions" data-vv-scope="default" id="type"
                            name="type" v-model="inStoreTime" :placeholder="'Select Time'" />
                    </v-col>
                </v-row>
                <v-row class="cut-off-row-container ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                        Online Cut Off Time:
                    </v-col>
                    <v-col class="ma-0 pa-0">
                        <HbSelect condensed box width="110px" :items="timeOptions" data-vv-scope="default" id="type"
                            name="type" v-model="onlineStoreTime" :placeholder="'Select Time'" />
                    </v-col>
                </v-row>
            </hb-form>
            <hb-form label="Space Grouping Profile" required v-if="isPropertyLevel">
                <hb-select v-model="form.spaceGroupProfile" name="space_group" data-vv-as="space grouping profile"
                    placeholder="Select Space Grouping Profile" :items="spaceGroups" item-text="name" :item-value="(value) => {
                        return { id: value.id, name: value.name };
                    }
                        " :clearable="false">
                </hb-select>
            </hb-form>
        </template>
        <template v-slot:footer>
            <hb-bottom-action-bar :top-border="false">
                <template v-slot:right-actions>
                    <hb-btn :disabled="apwSaveBtnDisable" @click="saveSetting">Save</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </template>
    </hb-expansion-panel>
</template>

<script>
import api from '../../../assets/api';
import { notificationMixin } from '../../../mixins/notificationMixin';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            spaceGroups: [],
            form: {
                spaceGroupProfile: {},
                instoreCutoffTime: '',
                onlineCutoffTime: '',
            },
            previousSpaceGroupProfile: {},
            timeOptions: ["12:00 AM", "1:00 AM", "2:00 AM", "3:00 AM", "4:00 AM", "5:00 AM", "6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM", "11:00 PM",],
            inStoreTime: '',
            onlineStoreTime: '',
            message: ['Cut-off Time Saved Successfully', 'Advance Reservation and Rental Space Grouping Profile has been updated successfully.'],
            apwSettingState: false,
        }
    },
    mixins: [notificationMixin],
    props: ['property_id', 'isPropertyLevel'],
    computed: {
        ...mapGetters({
        hasPermission: 'authenticationStore/rolePermission',
        }),
    },
    async created() {
        await this.fetchSettings()
        if (this.isPropertyLevel) {
            await this.fetchPropertySpaceGroup();
            await this.fetchSpaceGroup();
        }
        await this.fetchCutOffTime()
    },
    computed: {
        apwSaveBtnDisable(){
            if(this.inStoreTime != this.form?.instoreCutoffTime || this.onlineStoreTime != this.form?.onlineCutoffTime || this.previousSpaceGroupProfile?.unit_group_profile_id != this.form?.spaceGroupProfile?.id) {
                return false
            }else {
                return true
            }
        }
    },
    methods: {
        async fetchPropertySpaceGroup() {
            try {
                let response = await api.get(
                    this,
                    api.PROPERTIES + this.property_id + "/space-groups"
                );
                this.spaceGroups = response.spaceGroups;
            } catch (err) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async fetchSpaceGroup() {
            try {
                this.previousSpaceGroupProfile = await api.get(
                    this,
                    api.APW_SETTING + 'properties/' + this.property_id,
                );
                if (this.previousSpaceGroupProfile?.id) {
                    this.form.spaceGroupProfile = this.spaceGroups.find(group => group.id == this.previousSpaceGroupProfile.unit_group_profile_id)
                }
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async fetchCutOffTime() {
            try {
                let property_id = this.isPropertyLevel ? this.property_id : '';
                const response = await api.get(this, api.APW_SETTING + 'cut-off-time/' + property_id)
                if (response.length) {
                    this.form.instoreCutoffTime = this.inStoreTime = this.convertToAMPM(response[0].in_store_time)
                    this.form.onlineCutoffTime = this.onlineStoreTime = this.convertToAMPM(response[0].online_store_time)
                }
            } catch (error) {
                console.log("fetch cut off time error", error);
            }
        },
        async saveSetting() {
            const cutOffTImeData = {
                in_store_time: this.convertToUTC(this.inStoreTime),
                online_store_time: this.convertToUTC(this.onlineStoreTime),
                property_id: this.property_id,
                unit_group_profile_id: this.form.spaceGroupProfile.id,
                property_id: this.isPropertyLevel ? this.property_id : null
            }
            let data;
            let msg = this.message.slice() // create shallow copy
            if (this.previousSpaceGroupProfile?.id) {
                data = {
                    id: this.previousSpaceGroupProfile?.id,
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            } else {
                data = {
                    unit_group_profile_id: this.form.spaceGroupProfile.id
                };
            }
            try {
                if (!this.isPropertyLevel) {
                    // save/update cutOff time
                    await this.saveCutOffTime(cutOffTImeData)
                    msg.pop()

                }
                else {
                    // save/update both
                    await this.saveCutOffTime(cutOffTImeData)
                    await this.saveGrouping(data)
                }
                this.showMessageNotification({ type: 'success', description: "Success", list: msg });


            } catch (err) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        async saveGrouping(data) {
            await api.post(
                this,
                api.APW_SETTING + 'properties/' + this.property_id,
                data
            );
            // this.showMessageNotification({ type: 'success', description: 'Advance Reservation and Rental Space Grouping Profile has been updated successfully.' });
        },
        async saveCutOffTime(data) {
            await api.post(this, api.APW_SETTING + 'cut-off-time/', data)
            // return 'cut off time saved successfully'
        },
        convertToUTC(time) {
            const [timePart, modifier] = time.split(' ');
            let [hours, minutes] = timePart.split(':');
            hours = parseInt(hours, 10);
            minutes = parseInt(minutes, 10);

            if (modifier === 'PM' && hours !== 12) {
                hours += 12;
            } else if (modifier === 'AM' && hours === 12) {
                hours = 0;
            }

            // Create a Date object for today with the specified time
            const localDate = new Date();
            localDate.setUTCHours(hours, minutes, 0, 0); // Use setUTCHours to avoid local time zone conversion

            // Get the UTC timestamp directly
            const utcTimestamp = localDate.getTime();

            return utcTimestamp;
        },
        convertToAMPM(utcTimestamp) {
            const date = new Date(parseInt(utcTimestamp, 10));
            // Get the UTC time directly
            const hours = date.getUTCHours() % 12 || 12; // Convert '0' to '12'
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';

            return `${hours}:${minutes} ${ampm}`;
        },
        async fetchSettings() {
            let property_query=''
            if(this.isPropertyLevel){
                property_query = `&property_id=${this.property_id}`
            }
            let response = await api.get(this.$app, api.SETTINGS + `apw-details?category=leadManagement${property_query}`)
            // response value is in string

            let permission = this.hasPermission('view_or_edit_cut_off_times')
            this.apwSettingState = (response?.settings[0]?.value == 1) && permission  || false

        }
    },
    watch : {
        async isPropertyLevel(){
            await this.fetchSettings()
            await this.fetchCutOffTime()
        }
    }
}
</script>
<style scoped>
.cut-off-row-container {
    display: flex;
    align-items: center;
}
</style>